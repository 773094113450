@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css");
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

* {
  font-family: "poppins";
  box-sizing: border-box;
  list-style: none;
}

.custom-linkhref{
  text-decoration: none;
}
.custom-link {
  text-decoration: none;
  color: #212529; /* Default text color */
  transition: color 0.3s ease; /* Smooth color transition */
}

.custom-link:hover {
  color: #ff3d00; /* Hover color */
}

.atrips-home {
  overflow-x: hidden;
  
  .header-content {
    z-index: 15;
    background: #f3f3f3;
    position: fixed;
    height: 70px;
    width: 100%;

    .container {
      ul li {
        display: flex;
        padding: 12px;
      }

      .hambarg {
        transition: 0.3s;
        height: 46px;
        width: 46px;
        display: flex;
        border-radius: 50%;
        background: #d9d9d9;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        i {
          font-size: 36px;
        }
      }

      .hambarg:hover {
        transition: 0.3s;
        background-color: #d61313;
        color: #d9d9d9;

        i {
          background-color: none;
        }
      }

      .hambarg-btn {
        height: 46px;
        width: 46px;
        display: flex;
        border-radius: 50%;
        background: #d61313;
        color: #d9d9d9;
        justify-content: center;
        align-items: center;
        transition: 0.3s;
        cursor: pointer;

        i {
          font-size: 36px;
        }
      }

      .header-section {
        margin-bottom: 0%;
        display: flex;
        animation-duration: 1s;

        .active {
          color: #ff3d00;
        }

        li a {
          font-weight: 500;
          font-size: 1rem;
          cursor: pointer;

          a {
            font-weight: 500;
            font-size: 1rem;
            cursor: pointer;
            text-decoration: none;
            color: #000000;
          }
        }

        li a:hover {
          color: #ff3d00;
        }
      }

          


            @keyframes moveInRight {
        0% {
          opacity: 0;
          transform: translateX(100px);
        }

        80% {
          transform: translateX(-10px);
        }

        100% {
          opacity: 1;
          transform: translate(0);
        }
      }
    }
  }
// /* Mobile menu styles */
// @media (max-width: 768px) {
//   .container {
//     /* Ensure that the menu is hidden by default on mobile */
//     .header-section {
//       display: none; /* Hide the menu */
//       position: absolute;
//       top: 70px;
//       right: 0;
//       background: #fff;
//       width: 100%;
//       box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
//       border-radius: 8px;
//       flex-direction: column;
//     }

//     /* Display the menu when showNav is true */
//     .header-section.open {
//       display: flex;
//     }

//     /* Ensure the hamburger icon is visible */
//     .hambarg {
//       display: flex;
//       height: 46px;
//       width: 46px;
//       border-radius: 50%;
//       background: #d61313;
//       color: #fff;
//       justify-content: center;
//       align-items: center;
//       cursor: pointer;
//       position: absolute;
//       top: 15px;
//       right: 15px;
//     }

//     /* When the menu is open, change the hamburger icon to 'X' */
//     .hambarg-btn {
//       background: #d61313;
//       color: #fff;
//     }

//     /* Hamburger icon size and position */
//     .hambarg i {
//       font-size: 36px;
//     }

//     /* Optional: When the hamburger icon is hovered */
//     .hambarg:hover {
//       background-color: #ff3d00;
//     }
//   }
// }

  
  /* client section */
  .Client-container {
    .client-title {
      width: 85%;

      h2 {
        color: #042835;
        font-weight: 700;
        font-size: 45px;
      }
    }

    margin-top: 6rem;

    .slider-box {
      display: flex;
      margin-top: 2rem;

      .slider {
        cursor: pointer;
        margin-right: 1rem;
        width: 28px;
        height: 12px;
        background: #d9d9d9;
        border-radius: 12px;
      }

      .active {
        cursor: pointer;
        margin-right: 1rem;
        width: 48px;
        height: 12px;
        background: #e6262f;
        border-radius: 12px;
      }
    }

    div {
      h2 {
        font-weight: 700;
        font-size: 48px;
      }

      .circle {
        position: relative;
        height: 600px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        .logo {
          position: absolute;
          width: 550px;
          height: 550px;
          background-size: cover;
          border-radius: 50%;
          margin-left: 10px;

          .Roundcircle {
            position: relative;
            height: 557px;
          }

          .insidecirce {
            position: absolute;
            bottom: 32%;
            right: 28%;
          }
        }

        .text {
          position: absolute;
          width: 550px;
          height: 550px;
          border-radius: 50%;
          animation: rotateText 25s linear infinite;

          @keyframes rotateText {
            0% {
              transform: rotate(-360deg);
            }

            100% {
              transform: rotate(0deg);
            }
          }

          b {
            font-family: consolas;
            text-transform: uppercase;
            position: absolute;
            left: 50%;
            display: block;
            transform-origin: 0 275px;
            font-size: 16px;
          }
        }
      }

      .client-intelligent {
        p {
          margin-top: 2rem;
          word-spacing: 2px;

          color: #3d3d3d;

          span {
            font-size: 15px;
          }
        }
      }

      Button {
        width: 220px;
        height: 40px;
        background-color: #dd0505;
        border-radius: 4px;
        border: none;
        text-align: center;
        letter-spacing: 0.08em;
      }

      Button:hover {
        width: 220px;
        height: 40px;
        background-color: #042835;
        border-radius: 4px;
        border: none;
        text-align: center;
        letter-spacing: 0.08em;
        transition: 0.5s;
      }
    }
  }

  .corousel-container {
    .button {
      background-color: #dd0505;
      color: white;
      z-index: 1;
      width: 30%;
      max-width: 220px;
    }

    @media (max-width:768px) {
      img {
        top: 20%;
        width: 50%;
        z-index: 1;
      }

      .button {
        left: 35%;
        bottom: 5%;
      }
    }

    @media (min-width:769px) {
      img {
        top: 25%;
        right: 10%;
        width: 40%;
        z-index: 1;
      }

      .button {
        left: 15%;
        bottom: 30%;
      }
    }

    @media (max-width:425px) {
      .button {
        bottom: 8%;
        width: 30%;
        font-size: 12px;
      }
    }
  }


  .carousel-indicators {
    display: none;
  }

  .carousel-inner {

    @media (min-width:769px) {
      img {
        width: 100%;
        height: 85vh;
      }
    }

    @media (min-width:426px) and (max-width:768px) {
      img {
        width: 100%;
        height: 75vh;
      }
    }

    @media (max-width:426px) {
      img {
        width: 100%;
        height: 50vh;
      }
    }
  }

  .carousel-inner .carousel-caption {

    @media (max-width:425px) {
      top: 50%;
    }

    @media (min-width:426px) and (max-width:768px) {
      top: 60%;
    }

    @media (min-width:769px) {
      top: 30%;
      width: 35%;
    }

    @media (min-width:1024px) {
      h3 {
        font-size: 35px;
        font-weight: 700;
        text-align: left;
      }

      p {
        font-size: 15px;
        font-weight: 500;
        text-align: left;
      }
    }

    @media (min-width:769px) and (max-width:999px) {
      h3 {
        font-size: 25px;
        font-weight: 700;
        text-align: left;
      }

      p {
        font-size: 15px;
        font-weight: 500;
        text-align: left;
      }
    }

    @media (min-width:426px) and (max-width:768px) {
      h3 {
        font-size: 25px;
        font-weight: 700;
        text-align: center;
      }

      p {
        font-size: 15px;
        font-weight: 500;
        text-align: center;
      }
    }

    @media(max-width:425px) {
      h3 {
        font-size: 20px;
        font-weight: 700;
        text-align: center;
      }

      p {
        font-size: 10px;
        font-weight: 500;
        text-align: center;
      }
    }
  }

  .journeyMap-container {
    img {
      width: 100%;
    }
  }

  .aboutus-container {
    font-family: "Poppins", sans-serif;

    .inline-btn {
      width: 220px;
      background-color: #ff3d00;
      color: #ffffff;
      border: none;
      border-radius: 4px;
      padding: 10px;
    }

    .title {
      display: flex;
      justify-content: center;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 48px;
      margin-bottom: 40px;

      span {
        margin-left: 1rem;
        color: #ff3d00;
      }
    }
  }

  /* Career section */
  .Career-container {
    padding: 2rem;

    // padding-left: 1%;
    .career-btn {
      width: 220px;
      height: 46px;
      background: #ff3d00;
      border-radius: 4px;
      border: none;
    }

    .Career-header {
      font-family: "Inter";
      font-weight: "600";
      font-size: "48px";
    }

    .Career-content {
      padding: 0% 3%;

      .Career-content1 {
        font-weight: "400";
        font-size: "16px";
        font-family: "poppins";
        color: #88889b;
        text-align: justify;

        span {
          color: #ff3d00;
        }

        a {
          cursor: pointer;
          color: #88889b;
        }
      }
    }

    .career-img {
      margin-top: -16px;
      width: 374px;
      height: 318.18px;
    }
  }

  /* footer-section */
  .Footer-container {
    background-color: #EFEFEF;
    padding: 6rem;
    margin-top: 2rem;

    .scrollTop {
      position: fixed;
      width: 100%;
      bottom: 20px;
      align-items: end;
      height: 20px;
      justify-content: end;
      z-index: 0.1;
      cursor: pointer;
      animation: fadeIn 0.5s;
      transition: opacity 0.4s;
    }

    .scrollTop:hover {
      opacity: 1;
    }

    .colum-1 {
      .company-logo {
        width: 191px;
        height: 48px;
      }

      .details {
        display: flex;
        flex-direction: column;
        margin-top: 2rem;
      }

      .Footer-address {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-transform: capitalize;
      }

      .social-media {
        display: flex;
        padding: 0%;
        list-style: none;

        a {
          cursor: pointer;
        }

        .facebook {
          padding: 10px;
          color: #000;
        }

        .instagram {
          color: #000;
          padding: 10px;
        }

        .linkedin {
          padding: 10px;
          color: #000;
        }

        .skype {
          padding: 10px;
          color: #000;
        }
      }
    }

    .colum-2 {
      .company-name {
        font-weight: 600;
        font-size: 39.06px;
      }

      a {
        text-decoration: none;
        color: #000;
        cursor: pointer;
      }
    }

    .colum-3 {
      .Newsletter {
        font-weight: 600;
        font-size: 39.06px !important;
      }

      .footer-input {
        border-color: #ff3d00;
        border-radius: 0px;
        background-color: #042835;
        height: 60px;
        margin-left: 15px;
        color: #f0f8f4;
      }

      @media (min-width: 768px) {
        .input-box {
          height: 60px;
          width: 270px;
        }
      }
    }
  }

  .last-footer {
    .footer-last {
      display: flex;
      justify-content: right;
      align-items: flex-end;
      box-shadow: 2px 2px 4px rgba(255, 255, 255, 0.02);
    }

    .scrollTop {
      height: 42px !important;
      width: 42px;
      background: #ff3d00;
      position: fixed;
      bottom: 20px;
      border-radius: 50%;
      opacity: 0.5;
      align-items: end;
      justify-content: right;
      z-index: 15;
      cursor: pointer;
      color: #ffffff;
    }

    .scrollTop:hover {
      opacity: 1;
    }

    @media (max-width: 768px) {
      .Footer-arrow {
        padding-right: 2%;
      }
    }

    @media (min-width: 1440px) {
      .Footer-arrow {
        padding-right: 19%;
      }
    }

    @media (min-width: 1024px) {
      .Footer-arrow {
        padding-right: 14%;
      }
    }
  }

  // contact-form
  @media (max-width: 2000px) {
    .contact-form {
      display: flex;
      // justify-content: flex-end;
    }
  }

  @media (max-width: 1440px) {
    .contact-form {
      display: flex;
      justify-content: flex-end;
    }
  }

  .contact-container {
    background-color: #042835;
    padding: 2.5rem;
    height: 100%;
    align-items: center;
    // margin-top: 15rem;

    .Contact-address {
      color: white;

      .pin {
        position: absolute;
        width: 4%;
        min-width: 35px;
        cursor: pointer;

        &:hover {
          transform: scale(1.3);
        }
      }
    }

    a {
      color: white;
    }

    .contact-form {
      .card {
        background: #ffffff;
        border-radius: 8px;
        padding: 1rem;
        // bottom: 51%;
        width: 415px;
        height: 100%;

        .form {
          padding-left: 1rem;
        }

        .form-control {
          width: 338px;
          background-color: #f0f8f4;
        }

        Button {
          width: 184px;
          height: 44px;
          background: #ff3d0085;
          border-radius: 4px;
          border: none;
          text-align: center;
        }

        Button:hover {
          background: #ff3d00;
        }

        .form-control {
          width: 100%;
          height: 38px;
          background: #f0f8f4;
          border-radius: 0;
        }
      }
    }
  }

  /*Partial */
  .partial-container {
    margin-top: 3rem;
    padding: 2% 5%;
    background-color: #efefef;
    height: 100%;
    background-image: url('../src/assets/white_texture_bg2.avif'); /* Replace with your image path */
    background-size: cover; /* Ensures the image covers the entire div */
    background-position: center; /* Centers the image */
    background-repeat: no-repeat; /* Prevents the image from repeating */
    height: 100%; /* Optional: Set a height for the container */
    width: 100%; /* Optional: Set a width for the container */
    // h3 {
    //   font-weight: 900;
    //   font-size: 48px;
    //   text-align: center;
    //   line-height: 53px;
    //   color: #000000;
    //   padding: 15px;
    // }

    // .slick-list {
    //   padding: 0px 100px !important;
    // }

    .client-row {
      // width: 25% !important;
      display: flex;
      justify-content: center;

      .client-content {
        border-radius: 0px;
        margin: 3%;
        width: auto;
        // background: #efefef;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;

        .client-img1 {
          width: 170px;
          height: 140px;
          transition: transform 0.5s ease;
        }

        .partners-img {
          width: 150px;
          height: 100px;
          transition: transform 0.5s ease;
          object-fit: contain;
        }

        :hover {
          // -ms-transform: scale(1.5);
          // /* IE 9 */
          // -webkit-transform: scale(1.5);
          // /* Safari 3-8 */
          // transform: scale(1.2);
          object-fit: contain;
        }
      }

      // .client-content:hover {
      // }
      .client-img {
        width: 170px;
        height: 170px;
        transition: transform 0.5s ease;
        object-fit: contain;
      }

      .client-img:hover {
        -ms-transform: scale(1.5);
        /* IE 9 */
        -webkit-transform: scale(1.5);
        /* Safari 3-8 */
        transform: scale(1.3);
      }

      .pb-4 {
        display: flex;
        justify-content: space-between;
        margin: 50px;
      }

      .end-title {
        font-weight: 500;
        font-size: 16px;
        display: flex;
        justify-content: center;
        color: #e6262f;
        padding-bottom: 40px;
        text-decoration: none;
        cursor: pointer;
      }
    }
  }

  //proud-members-design


  //---------------------------

  // formy card gradient effect
  .spectacledcoder-card2 {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    background-color: #ffffff;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: all 0.5s ease-in-out;
    overflow: hidden;
  }

  .circle2 {
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // width: 50px;
    // height: 50px;
    // background-color: rgb(42, 42, 42);
    // border-radius: 50%;
    // transform: translate(-50%, -50%);
    // transition: all 0.5s ease-in-out;
    position: absolute;
    bottom: 0;
    left: 0;
    margin: auto;
    margin-top: 35px;
    width: 25px;
    height: 25px;
    // background-color: rgb(255, 201, 201);
    background: linear-gradient(to right, #dd0505, #212529);
    border-radius: 0px 50px 0px 15px;
    transition: all 0.5s ease-in-out;
  }
  
  .spectacledcoder-card2:hover .circle2 {
    width: 250px;
    height: 150px;
    border-radius: 15px;
  }
  
  .spectacledcoder-card2 .content {
    position: relative;
    z-index: 1;
    text-align: center;
  }
  
  .spectacledcoder-card2:hover .content h5 {
    color: white;
  }
  
  .spectacledcoder-card2 .partners-img {
    height: 100px;
    width: auto;
    object-fit: contain;
  }


  
  // stragetic-partner-card gradient effect
  .stragetic-partner-card {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // width: 200px;
    // height: 200px;
    margin: auto;
    background-color: white;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: all 0.5s ease-in-out;
    overflow: hidden;
  }
  
  .circle2 {
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // width: 50px;
    // height: 50px;
    // background-color: rgb(42, 42, 42);
    // border-radius: 50%;
    // transform: translate(-50%, -50%);
    // transition: all 0.5s ease-in-out;
    position: absolute;
    margin: auto;
    margin-top: 35px;
    width: 5px;
    height: 5px;
    bottom: 0;
    left: 0;
    // background-color: rgb(255, 201, 201);
    // background: linear-gradient(to right, #dd0505, #215461);
    // background: linear-gradient(to right, #dd0505 0%, #eb1a47 50%, #215461 100%);
    // background: radial-gradient(circle, #dd0505 20%, #8b2a33 50%, #215461 80%);
    background: conic-gradient(from 0deg, #dd0505, #8b2a33, #215461, #dd0505);
    border-radius: 0px 50px 0px 15px;
    transition: all 0.5s ease-in-out;
  }
  
  .stragetic-partner-card:hover .circle2 {
    width: 300px;
    height: 150px;
    border-radius: 15px;
  }
  
  .stragetic-partner-card .content {
    position: relative;
    z-index: 1;
    text-align: center;
  }
  
  .stragetic-partner-card:hover .content h5 {
    color: white;
  }
  
  .stragetic-partner-card .partners-img {
    height: 100px;
    width: auto;
    object-fit: contain;
  }

  .section-title {
    font-size: clamp(1.2rem, 4vw, 2rem); /* Adjusts between 1.5rem and 2.5rem based on screen width */
    font-weight: bold;
    color: #333;
    text-align: center;
    margin-bottom: 2.5rem;
  }


  /* Card container */
.proud-member-card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  background-color: white;
  border-radius: 15px;
  width: 200px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: all 0.5s ease-in-out;
  overflow: hidden;
}

/* Gradient effect layer */
.proud-member-card .gradient-circle {
  //  position: absolute;
  //   top: 50%;
  //   left: 50%;
  //   width: 50px;
  //   height: 50px;
  //   background: linear-gradient(to right, #dd0505, #212529);
  //   border-radius: 50%;
  //   transform: translate(-50%, -50%);
  //   transition: all 0.5s ease-in-out;
  position: absolute;
  margin: auto;
  margin-top: 35px;
  width: 25px;
  height: 25px;
  bottom: 0;
  left: 0;
  background: linear-gradient(to right, #dd0505, #212529);
  border-radius: 0px 50px 0px 15px;
  transition: all 0.5s ease-in-out;
}

/* On hover, increase the size of the gradient effect */
.proud-member-card:hover .gradient-circle {
  width: 400px;
  height: 150px;
  border-radius: 15px;
}

/* Card content */
.proud-member-card .content {
  position: relative;
  z-index: 1;
  text-align: center;
}

/* Card text (name) */
.proud-member-card:hover .content h5 {
  color: white;
}

/* Image inside the card */
.proud-member-card .partner-img {
  height: 100px;
  width: auto;
  object-fit: contain;
}


  
.skill-enablers-container {
  // margin-top: 3rem;
  padding: 1rem;
  // background: linear-gradient(to right, #f3f4f6, #e8ecef); /* Modern gradient */
  // background: linear-gradient(to right, #ffedeb, #ffe8e8); /* Elegant gradient */
}

.proud-members-container {
  // margin-top: 3rem;
  padding: 1rem ;
  // background: linear-gradient(to right, #ffedeb, #ffe8e8); /* Elegant gradient */
}

//   /*Partial-Others */
//   .partial-container-others {
//     // margin-top: 3rem;
//     padding: 3% 7%;
//     // background-color: #5f8694;
//     background: linear-gradient(to right, #ffedeb, #ffe8e8); /* Elegant gradient */
//     height: 100%;

//     .client-row {
//       // width: 25% !important;
//       display: flex;
//       justify-content: center;

//       .client-content {
//         border-radius: 10px;
//         margin: 3%;
//         width: auto;
//         // background: #efefef;
//         // background: linear-gradient(to right, #ffedeb, #ffe8e8); /* Elegant gradient */
//         border: none;
//         display: flex;
//         align-items: center;
//         justify-content: center;

//         .client-img1 {
//           width: 170px;
//           height: 140px;
//           transition: transform 0.5s ease;
//         }

//         .partners-img {
//           width: 150px;
//           height: 100px;
//           transition: transform 0.5s ease;
//           object-fit: contain;
//         }

//         .partners-img-slider {
//           width: 150px;
//           height: 130px;
//           transition: transform 0.5s ease;
//           object-fit: contain;
//         }
//         :hover {
//           // -ms-transform: scale(1.5);
//           // /* IE 9 */
//           // -webkit-transform: scale(1.5);
//           // /* Safari 3-8 */
//           // transform: scale(1.2);
//           object-fit: contain;
//         }
//       }

//       // .client-content:hover {
//       // }
//       .client-img {
//         width: 170px;
//         height: 170px;
//         transition: transform 0.5s ease;
//         object-fit: contain;
//       }

//       .client-img:hover {
//         -ms-transform: scale(1.5);
//         /* IE 9 */
//         -webkit-transform: scale(1.5);
//         /* Safari 3-8 */
//         transform: scale(1.3);
//       }

//       .pb-4 {
//         display: flex;
//         justify-content: space-between;
//         margin: 50px;
//       }

//       .end-title {
//         font-weight: 500;
//         font-size: 16px;
//         display: flex;
//         justify-content: center;
//         color: #e6262f;
//         padding-bottom: 40px;
//         text-decoration: none;
//         cursor: pointer;
//       }
//     }
//   }


// /* Responsive design adjustments */
// @media (max-width: 768px) {
//   .partial-container-others {
//     padding: 5% 3%;

//     .client-row {
//       .client-content {
//         margin: 3%; /* Slightly more margin for spacing */
//       }
//     }
//   }
// }

// @media (max-width: 576px) {
//   .partial-container-others {
//     padding: 5% 2%;

//     .client-row {
//       .client-content {
//         margin: 5%; /* Adjust margin for small screens */
//       }

//       .partners-img,
//       .client-img1,
//       .partners-img-slider,
//       .client-img {
//         width: 120px;
//         height: auto; /* Maintain aspect ratio */
//       }
//     }

//     .end-title {
//       font-size: 14px; /* Reduce font size for small screens */
//     }
//   }
// }

/* General container styling */
.other-partners-container {
  padding: 0;
  background-color: #f3f3f3;
  // background-color: #212529;

}


/* Marquee wrapper styling */
.marquee-wrapper {
  width: 100%; /* Full-width marquee */
  padding: 25px 0;
  // background: linear-gradient(90deg, #ffffff, #f1f5f9); /* Subtle gradient background */
}

/* Marquee items wrapper */
.marquee-item {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 20px; /* Consistent spacing between cards */
}

/* Marquee card styling */
.marquee-card {
  width: 180px; /* Compact width */
  height: 130px; /* Compact height */
  background: #ffffff; /* Card background */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  display: flex;
  justify-content: center;
  align-items: center;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  border: 2px solid #ddd;
  /* Hover effect for the card */
  &:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); /* Enhance shadow on hover */
  }
}

/* Image inside the marquee card */
.partners-img-marquee {
  width: 110px; /* Adjust image size */
  height: auto; /* Maintain aspect ratio */
  object-fit: contain; /* Prevent image distortion */
  transition: transform 0.3s ease;
  /* Hover effect for the image */
  &:hover {
    transform: scale(1.05); /* Slight zoom effect */
  }
}

/* Responsive design adjustments */
@media (max-width: 992px) {
  .marquee-card {
    width: 100px; /* Smaller card size for medium screens */
    height: 80px;
  }

  .partners-img-marquee {
    width: 70px; /* Reduce image size */
  }
}

@media (max-width: 768px) {
  .marquee-card {
    width: 90px; /* Smaller cards for tablets */
    height: 70px;
  }

  .partners-img-marquee {
    width: 60px; /* Reduce image size for smaller screens */
  }

  .marquee-item {
    margin: 0 10px; /* Reduce spacing between items */
  }
}

@media (max-width: 576px) {
  .marquee-card {
    width: 80px; /* Compact cards for mobile */
    height: 80px;
  }

  .partners-img-marquee {
    width: 50px; /* Smallest size for mobile */
  }

  .marquee-item {
    margin: 0 8px; /* Minimal spacing for small screens */
  }
}



  /*Solution */
  .solution-container {
    padding: 1% 5%;
    font-size: 18px;

    // .slick-list {
    //   padding: 0px !important;
    // }
    h3 {
      font-size: 48px;
      font-weight: 600;
      line-height: 72px;
      letter-spacing: 0em;
      text-align: center;
    }

    .solution-content {
      display: flex;
      justify-content: center;
      color: #88889b;

      p {
        width: 672px;
        font-weight: 500;
        font-size: 16px;
        text-align: center;
      }
    }

    .button {
      height: 70px;
    }

    .next-solution {
      width: 42px;
      position: absolute;
      right: 21px;
      border-radius: 50%;
      height: 42px;
      margin-top: 412px;
      opacity: 0.5;
    }

    .prev-solution {
      width: 42px;
      border-radius: 50%;
      position: absolute;
      height: 42px;
      left: 35px;
      // right: 75rem;
      margin-top: 412px;
      opacity: 0.5;
    }

    body {
      font-family: "Poppins", sans-serif;
    }

    .pg-1 {
      display: flex;
      justify-content: space-between;
    }

    .card {
      height: 100%;
      border: none;
      margin: 1rem;
    }

    .img-1 {
      background: #ffffff;
      border-radius: 4px;
      width: 384px;
      height: 243px;
    }

    .card-body {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      padding: 1rem;
    }

    // .title {
    //   color: #ff3d00;
    //   font-weight: 500;
    //   font-size: 20px;
    //   text-transform: capitalize;
    //   margin: 0rem;
    //   margin-bottom: 8px;
    // }

    .content {
      color: #818181;
      font-weight: 400;
      font-size: 14px;
      margin-bottom: 8px !important;
    }

    .r-more {
      font-family: "Poppins", sans-serif;
      text-decoration: none;
      color: #ff3d00;
      font-weight: 500;
      font-size: 16px;
      cursor: pointer;
      line-height: 24px;
    }
  }

  /*atribs */
  .atribs-container {
    font-family: "Poppins", sans-serif;
    padding-left: 2rem;
    padding-top: 0;

    // .title {
    //   font-weight: 600;
    //   font-size: 48px;
    //   display: flex;
    //   justify-content: center;
    //   padding-top: 65px;
    //   padding-bottom: 2rem;
    // }

    .content-1 {
      font-weight: 700;
      font-size: 64px;
      border: none !important;
    }

    .letter {
      position: relative;
      display: flex;
      justify-content: center;
      bottom: 250px;
      color: white;
      font-weight: 700;
      font-size: 64px;
    }

    .card {
      position: relative;
      margin-bottom: 12%;
      background-color: black;
      overflow: hidden;
      height: 374px;
      display: flex;
      align-items: center;
      justify-content: center;

      .title {
        position: absolute;
        color: #ffff;
        font-family: "Poppins", sans-serif;
        transition: 1s;
      }

      .c-title {
        font-size: 60px;
        font-weight: 700;
      }

      .o-title {
        .chart {
          height: 44px;
          width: 44px;
        }

        span::first-letter {
          font-size: 44px;
          font-weight: bold;
        }

        font-size: 32px;
        font-weight: 500;
        display: flex;
        flex-direction: column;
        transition: 1s;
        transform: rotate(360deg);
      }

      .card-img-top {
        transform: scale(1.06);
        opacity: 0.3;
      }
    }
  }

  /* automation */
  .automation {
    margin-top: 2rem;

    h2 {
      font-weight: 600;
      font-size: 48px;
      color: #3d3d3d;
    }

    .container {
      margin-top: 2rem;

      .content {
        span {
          color: #000000;
        }
      }

      p {
        font-weight: 500;
        font-size: 16px;
        color: #b5b5b5;
        text-align: justify;
      }
    }
  }

  /* about*/
  .about-container {
    font-family: "Poppins", sans-serif;

    .title-col {
      color: #ff3d00;
    }

    .title {
      display: flex;
      justify-content: center;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 48px;
      margin-bottom: 40px;

      span {
        margin-left: 1rem;
        color: #ff3d00;
      }
    }

    .bdy-content {
      display: flex;
      justify-content: space-evenly;
      font-weight: 500;
      font-size: 24px;
      margin-bottom: 20px;
      margin-left: 40px;

      .active-border {
        display: flex;

        .red-b {
          width: 45px;
          height: 5px;
          background: #ff3d00;
        }

        .black-b {
          width: 100px;
          height: 2px;
          background: #88889b;
        }
      }
    }

    .img-col {
      margin-bottom: 10px;
    }

    .aboutTab {
      margin: 0 10%;
    }

    .about-col {
      position: relative;
      display: flex;
      justify-content: end;

      .text-col {
        background-color: white;
        position: absolute;
        font-weight: 400;
        font-size: 16px;
        width: 545px;
        text-align: justify;
        padding: 15px;
        right: 5.95%;
        bottom: 20.04%;
        color: #88889b;
      }
    }

    .icon-col {
      color: #000000;
      margin: 20px;
    }

    .active-title {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      span {
        color: #ff3d00;
      }
    }

    .active {
      cursor: pointer;
    }

    .btn {
      display: flex;
      justify-content: end;
    }

    .inline-btn {
      width: 220px;
      background-color: #ff3d00;
      color: #ffffff;
      border: none;
      border-radius: 4px;
      padding: 10px;
    }

    .bdy-content {
      display: flex;
      justify-content: flex-start;
      cursor: pointer;
    }
  }
}

.banner {
  position: relative;

  .img {
    opacity: 0.5;
    width: 100%;
  }

  .title {
    position: absolute;
    color: #313131;
    font-weight: 600;
    font-size: 36px;
    display: flex;
    left: 44rem;
    top: 20rem;
  }

  .arrow {
    display: flex;
    position: absolute;
    font-size: 40px;
    bottom: 8rem;
    left: 3rem;
    cursor: pointer;
  }
}

.content-row {
  padding: 3rem 3rem 0 3rem;

  .content {
    color: #818181;
    font-weight: 500;
    font-size: 18px;
    text-align: justify;
  }
}

.title {
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  padding-top: 2%;
  text-align: center;
}


.title-text {
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  padding-bottom: 2%;
  text-align: center;
  // color: white;
}

.title-strategic {
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  padding-bottom: 2%;
  text-align: center;
}
// card bg- change
.cardbg-test {
  position: relative;
  z-index: 0;
  overflow: hidden;
  border-radius: 10px; /* Consistent rounded corners */
  background: #ffffff; /* Ensure consistent background */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Default shadow */

  /* Background animation */
  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: -16px;
    left: -30px;
    background: #114f4f; /* Dark green */
    height: 32px;
    width: 32px;
    border-radius: 32px;
    transform: scale(1);
    transform-origin: 50% 50%;
    transition: transform 0.25s ease-out;
  }

  &:hover:before {
    transform: scale(17);
  }

  &:hover {
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2); /* Stronger shadow on hover */
  }

  /* Text hover effects */
  p {
    transition: all 0.3s ease-out;
    color: rgba(255, 255, 255, 0.8);
  }

  h5 {
    transition: all 0.3s ease-out;
    color: #ffffff;
  }
}



@media (min-width:426px) and (max-width:768px) {
  .title {
    font-size: 35px;
  }
  .title-text {
    font-size: 35px;
  }
}

@media (max-width:425px) {
  .title {
    font-size: 25px;
  }
  .title-text {
    font-size: 25px;
  }
}

/* forte section */
.forte-container {
  margin-top: 1rem;
  background-image: url('./assets/OurForteBG.png');
  color: #fff;

  .forteTabs {
    background: #ffffff;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    width: 80%;
    margin-top: 48px;

    button {
      font-weight: 600;
      font-size: 20px;
      display: flex;
      justify-content: center;
      padding: 10px;
      background: none;
      border: none;
      color: #2b2b2b;
    }

    .active {
      background: linear-gradient(232.31deg, #ff5620 -17.44%, #3d3dff 147.56%);
      border-radius: 5px;
      color: white;
    }
  }

  .container-forte-final {
    background: none;
  }

  .forte-content {
    margin-top: 3%;
    padding: 3%;
    display: flex;
    flex-wrap: wrap;

    .leftsideContent {
      display: flex;
      justify-content: center;

      .img-card {
        height: 570px;
        width: 360px;
        border-radius: 12px;
        position: absolute;
        z-index: -10;
        position: relative;
      }
    }

    .rightsideContent {
      .rightsideHead {
        display: flex;
        align-items: center;

        h2 {
          font-weight: 600;
          font-size: 28px;
        }

        .btnGroup {
          Button {
            height: 40px;
            width: 40px;
            border-radius: 5px;
            background-color: #fff;
            color: #ff3d00;
            border-color: #ff3d00;
            margin: 0.5rem;
          }

          .active {
            background-color: #ff3d00;
            color: #ebe5e5;
            border: none;
          }
        }
      }
    }

    .card {
      position: absolute;
      right: 12%;
      width: 840px;
      height: 580px;
      z-index: 10;
      margin-top: 5%;
      padding: 45px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 5px;
      border: none;

      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 33px;
        color: #b5b5b5;
        text-align: justify;
      }

      li {
        font-weight: 400;
        font-size: 16px;
        line-height: 33px;
        color: #9b9b9b;
        text-align: justify;
        list-style: circle;
      }

      .content {
        span {
          color: #000000;
        }
      }
    }
  }
}

/* solution readmore secrtion */
.solution-readmore {
  overflow: hidden;

  .left-arrow {
    margin-left: 1%;
    margin-top: 0%;
    cursor: pointer;
    position: absolute;
    background-color: #114957;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    transition: transform 0.2s;
    height: 40px;
    box-shadow: 0 0 50px #f9bebe;

    i {
      font-size: 1.1rem;
      color: #ffffff;
      width: 11px;
      height: 11px;
    }
  }

  .left-arrow:hover {
    background-color: #e2262f;
    -ms-transform: scale(1);
    /* IE 9 */
    -webkit-transform: scale(1);
    /* Safari 3-8 */
    transform: scale(1.1);
  }

  .solution-readcontent {
    padding: 2rem;
    margin-top: 2rem;
    display: flex;
    flex-direction: row;

    .content {
      span {
        color: #000000;
      }
    }

    .Footer-container {
      background-color: #EFEFEF;
      padding: 6rem;
      margin-top: 2rem;

      .colum-1 {
        .company-logo {
          width: 191px;
          height: 48px;
        }

        .details {
          display: flex;
          flex-direction: column;
          margin-top: 2rem;
        }

        .Footer-address {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          text-transform: capitalize;
        }

        .social-media {
          display: flex;
          padding: 0%;
          list-style: none;

          .facebook {
            padding-right: 10px;
          }

          .instagram {
            padding: 10px;
          }

          .Twitter {
            padding: 10px;
          }

          .skype {
            padding: 10px;
          }
        }
      }

      .colum-2 {
        .company-name {
          font-weight: 600;
          font-size: 39.06px;
        }

        a {
          text-decoration: none;
          color: azure;
        }
      }

      .colum-3 {
        .Newsletter {
          font-weight: 600;
          font-size: 39.06px !important;
        }

        .footer-input {
          border-color: #ff3d00;
          border-radius: 0px;
          background-color: #06442f;
          height: 60px;
          margin-left: 15px;
          color: #f0f8f4;
        }

        @media (min-width: 768px) {
          .input-box {
            height: 60px;
            width: 270px;
          }
        }
      }
    }

    p {
      font-weight: 400;
      font-size: 16px;
      color: #818181;
    }

    h2 {
      span {
        color: #e6262f;
      }
    }

    ul {
      padding-top: 1%;

      li {
        font-weight: 400;
        font-size: 16px;
        color: #818181;
        padding: 0;
      }
    }

    .readmore-img {
      display: flex;
      justify-content: center;
      height: 100%;
      margin-bottom: 4%;

      img {
        transition: transform 0.5s;
        height: 500px;
        box-shadow: 0.5rem 0.5rem #042835, -0.5rem -0.5rem #dd0505;
        border-radius: 30px;
      }

      img:hover {
        -ms-transform: scale(1);
        /* IE 9 */
        -webkit-transform: scale(1);
        /* Safari 3-8 */
        transform: scale(1.03);
      }
    }
  }
}

.webreathe-container {
  margin-top: 3rem;
  padding: 8%;
  background-color: #efefef;
  height: 100% !important;

  .left-arrow {
    top: 15%;
    left: 2%;
    margin-left: 1%;
    margin-top: 0%;
    cursor: pointer;
    position: absolute;
    background-color: #114957;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    transition: transform 0.2s;
    height: 40px;
    cursor: pointer;

    i {
      font-size: 1.1rem;
      color: #ffffff;
      width: 11px;
      height: 11px;
    }
  }

  .left-arrow:hover {
    background-color: #e2262f;
    -ms-transform: scale(1);
    /* IE 9 */
    -webkit-transform: scale(1);
    /* Safari 3-8 */
    transform: scale(1.1);
  }

  h3 {
    font-weight: 900;
    font-size: 48px;
    text-align: center;
    line-height: 53px;
    color: #000000;
    padding: 15px;
  }

  // .slick-list {
  //   padding: 0px 100px !important;
  // }

  .client-row {
    // width: 25% !important;
    display: flex;
    justify-content: center;

    .client-content {
      height: 240px;
      width: 282px;
      border-radius: 10px;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
        rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
      margin: 3%;
      width: auto;
      background: #ffffff;
      display: flex;
      align-items: center;
      // flex-direction: column;
      justify-content: center;

      .client-img1 {
        width: 170px;
        height: 140px;
        transition: transform 0.5s ease;
      }

      .partners-img {
        width: 150px;
        height: 115px;
        transition: transform 0.5s ease;
      }

      :hover {
        -ms-transform: scale(1.5);
        /* IE 9 */
        -webkit-transform: scale(1.5);
        /* Safari 3-8 */
        transform: scale(1.3);
        object-fit: contain;
      }
    }

    .client-content:hover {
      box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
      border-radius: 10px;
    }

    .client-img {
      width: 170px;
      height: 130px;
      transition: transform 0.5s ease;
      object-fit: contain;
    }

    .client-img:hover {
      -ms-transform: scale(1.5);
      /* IE 9 */
      -webkit-transform: scale(1.5);
      /* Safari 3-8 */
      transform: scale(1.3);
    }

    .pb-4 {
      display: flex;
      justify-content: space-between;
      margin: 50px;
    }

    .end-title {
      font-weight: 500;
      font-size: 16px;
      display: flex;
      justify-content: center;
      color: #e6262f;
      padding-bottom: 40px;
      text-decoration: none;
      cursor: pointer;
    }
  }

  .Footer-container {
    background-color: #EFEFEF;
    padding: 6rem;
    margin-top: 2rem;

    .colum-1 {
      .company-logo {
        width: 191px;
        height: 48px;
      }

      .details {
        display: flex;
        flex-direction: column;
        margin-top: 2rem;
      }

      .Footer-address {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-transform: capitalize;
      }

      .social-media {
        display: flex;
        padding: 0%;
        list-style: none;

        .facebook {
          padding-right: 10px;
        }

        .instagram {
          padding: 10px;
        }

        .Twitter {
          padding: 10px;
        }

        .skype {
          padding: 10px;
        }
      }
    }

    .colum-2 {
      .company-name {
        font-weight: 600;
        font-size: 39.06px;
      }

      a {
        text-decoration: none;
        color: azure;
        cursor: pointer;
      }
    }

    .colum-3 {
      .Newsletter {
        font-weight: 600;
        font-size: 39.06px !important;
      }

      .footer-input {
        border-color: #ff3d00;
        border-radius: 0px;
        background-color: #06442f;
        height: 60px;
        margin-left: 15px;
        color: #f0f8f4;
      }

      @media (min-width: 768px) {
        .input-box {
          height: 60px;
          width: 270px;
        }
      }
    }
  }
}

// media query
@media (min-width: 1500px) and (max-width: 2560px) {
  .forte-container .forte-content .card {
    right: 10%;
    width: 60%;
  }
}

@media (min-width: 1200px) and (max-width: 1500px) {
  .forte-container .forte-content .card {
    right: 8%;
    width: 60%;
  }
}

@media (max-width: 1440px) {
  .career-container {
    .careerLogo {
      display: flex;
      justify-content: flex-end;
    }
  }
}

@media (max-width: 2000px) {
  .career-container {
    .careerLogo {
      display: flex;
      justify-content: flex-end;
    }
  }
}

@media (min-width: 850px) and (max-width: 1200px) {
  .careerLogo img {
    width: 95%;
  }
}

@media (min-width: 575px) and (max-width: 849px) {

  // .atrips-home {
  //   padding-left: 2px;
  //   padding-right: 2px;
  // }
  .Career-container {
    .career-container {
      display: flex;
      flex-direction: column;
    }

    .career-container {
      .Career-content {
        width: 100%;
        padding: 0;
        display: flex;
        align-items: center;
      }

      .careerLogo {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
      }
    }
  }
}

@media (min-width: 275px) and (max-width: 574px) {
  .atrips-home .Career-container {
    padding: 3rem;
  }

  .aboutus-container {
    padding: 1.5rem;
  }
}

@media (max-width: 1024px) {
  .atrips-home {
    .about-container {
      .about-col {
        .text-col {
          background-color: white;
          position: absolute;
          font-weight: 400;
          font-size: 12px;
          width: 545px;
          height: auto;
          text-align: justify;
          padding: 15px;
          right: 5.95%;
          bottom: 9% !important;
          color: #88889b;
        }
      }
    }

    .solution-container {
      .prev-solution {
        width: 42px;
        border-radius: 50%;
        position: absolute;
        height: 42px;
        right: 60rem !important;
        margin-top: 412px;
        opacity: 0.5;
      }
    }

    .forte-container {
      .forte-content {
        .card {
          position: absolute;
          right: 4%;
          width: 650px;
          overflow: auto;
          height: 511px;
          z-index: 10;
          margin-top: 12%;
          padding: 45px;
          background: #ffffff;
          box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
          border-radius: 5px;
          border: none;
        }
      }
    }
  }
}

@media (max-width: 1220px) {
  .atrips-home {
    .forte-container {
      .forte-content {
        .card {
          position: absolute;
          right: 8%;
          width: 55%;
          overflow: auto;
          height: 511px;
          z-index: 10;
          margin-top: 12%;
          padding: 45px;
          background: #ffffff;
          box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
          border-radius: 5px;
          border: none;
        }
      }
    }
  }
}

@media (max-width: 1100px) {
  .solution-readmore .solution-readcontent {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 768px) {
  .responsive-container-design {
    display: flex;
    justify-content: flex-start;
  }

  .career-container {
    .careerLogo {
      display: flex;
      justify-content: center;
    }
  }

  .Career-container .row {
    display: flex;
    justify-content: center;
  }

  .contact-container .row {
    display: flex;
    // flex-direction: column;
    align-items: center;
  }

  .atrips-home .contact-form {
    display: flex;
    justify-content: center;
  }

  .atrips-home .contact-container {
    margin-top: 0%;
  }

  .contact-container {
    .Contact-address {
      margin-top: 3rem;
    }
  }

  .atrips-home {
    .header-content {
      .container {
        .hambarg-btn {
          height: 3rem !important;
          width: 3rem !important;
        }
      }
    }

    .banner {
      .img {
        height: 14rem;
        width: 700px;
      }

      .title {
        position: absolute;
        color: #313131;
        font-weight: 600;
        font-size: 25px;
        display: flex;
        left: 170px;
        top: 15rem;
      }

      .arrow {
        position: absolute;
        font-size: 40px;
        display: flex;
        font-size: 40px;
        left: 2rem;
        top: 233px;
      }
    }

    .content-row {
      padding: 4rem 1.5rem 0 1.5rem;
    }

    .Client-container {
      .circle {

        // height: 450px !important;
        .logo {
          .Roundcircle {
            height: 349px !important;
            left: 19%;
            top: 19%;
          }

          .insidecirce {
            top: 31%;
            left: 35%;
            width: 165px;
            height: 211px;
          }
        }

        .text {
          width: 350px !important;
          height: 350px !important;

          b {
            transform-origin: 0 176px !important;
            font-size: 12px !important;
          }
        }
      }
    }

    .contact-container {
      // margin-top: 40%;
      height: 100%;

      .contact-form {
        .card {
          width: 360px;
          height: 500px;
          bottom: 60%;

          .form-control {
            padding-top: 1rem;
            height: 2rem;
            width: 100%;
          }
        }
      }
    }

    .Footer-container {
      .colum-2 {
        a {
          cursor: pointer;
        }

        .company-name {
          font-size: 25px;
        }
      }

      .colum-3 {
        .Newsletter {
          font-weight: 600;
          font-size: 25px !important;
        }
      }
    }

    .about-container {
      display: none;

      .about-col {
        .text-col {
          background-color: white;
          position: absolute;
          font-weight: 400;
          font-size: 12px;
          width: 545px;
          height: auto;
          text-align: left;
          padding: 15px;
          right: 5.95%;
          bottom: 12%;
          color: #88889b;
        }
      }
    }

    .atribs-container {
      .card {
        .o-title {
          font-size: 24px;
          font-weight: 500;
        }
      }
    }

    .solution-container {

      // .slick-slide {
      //   width: auto !important;
      // }
      .scroll {
        display: none !important;
      }
    }

    .career-container {
      .careerLogo {
        padding: 0;

        img {
          height: 250px;
        }
      }
    }

    .forte-container {
      .forte-mb {
        display: none;
      }

      .forte-content {
        .card {
          position: absolute;
          right: 10%;
          width: 55%;
          overflow: auto;
          height: 511px;
          z-index: 10;
          margin-top: 14%;
          padding: 45px;
          background: #ffffff;
          box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
          border-radius: 5px;
          border: none;
        }
      }
    }

    .header-content {
      z-index: 15;
      background: #f3f3f3;
      position: fixed;
      height: 70px;
      width: 100%;

      .container {
        img {
          width: 160px;
        }

        .hambarg {
          height: 46px;
          width: 46px;
          // display: flex;
          display: none;
          border-radius: 50%;
          background: #d9d9d9;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }

        .hambarg:hover {
          background-color: #d61313;

          i {
            background-color: none;
          }
        }

        .hambarg-btn {
          height: 40px;
          width: 46px;
          display: flex;
          border-radius: 50%;
          background: #d61313;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }

        .header-section {
          margin-bottom: 0%;
          display: flex;
          flex-direction: column;
          top: 74px;
          right: 0;
          position: absolute;
          height: 70vh;
          overflow: scroll;
          width: auto;
          animation-duration: 81s;
          background-color: #fff;
          border-radius: 4%;
          padding: initial;
          animation: 0.5s linear forwards alternate sun-rise;

          .active {
            color: #ff3d00;
          }

          li a {
            font-weight: 500;
            font-size: 13px;
            cursor: pointer;
          }
        }

        @keyframes sun-rise {
          from {
            transform: translateX(120vh);
          }

          to {
            transform: translateX(0);
          }
        }
      }
    }

    // for mobile view menu icon with header-section
    /* For mobile view */
@media (max-width: 768px) {
  .header-content {
    z-index: 15;
    background: #f3f3f3;
    position: fixed;
    height: 70px;
    width: 100%;

    .container {
      img {
        width: 160px;
      }

      /* Hamburger icon */
      .hambarg {
        display: flex;  /* Make hamburger icon visible */
        height: 46px;
        width: 46px;
        border-radius: 50%;
        background: #d9d9d9;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }

      .hambarg:hover {
        background-color: #d61313;
      }

      /* Active hamburger button (after click) */
      .hambarg-btn {
        height: 40px;
        width: 46px;
        display: flex;
        border-radius: 50%;
        background: #d61313;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }

      .hambarg-btn i {
        font-size: 24px;
        color: white;
      }

      /* Menu section (Initially hidden) */
      .header-section {
        display: none; /* Hide by default */
        position: absolute;
        top: 74px;
        right: 0;
        background-color: #fff;
        width: 40%;
        height: 70vh;
        overflow: auto;
        padding: 10px;
        border-radius: 4%;
        flex-direction: column;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        animation: 0.5s linear forwards alternate sun-rise;
      }

      /* Show menu when open */
      .header-section.open {
        display: flex; /* Show menu when showNav is true */
      }

      /* Menu animation */
      @keyframes sun-rise {
        from {
          transform: translateX(120vh);
        }

        to {
          transform: translateX(0);
        }
      }

      .header-section li a {
        font-weight: 500;
        font-size: 1rem;
        cursor: pointer;
        color: #212529;
      }

      .header-section .active {
        color: #ff3d00;
      }
    }
  }
}


    .aboutus-container {
      img {
        width: 100%;
        height: 109px;
      }

      p {
        margin-top: 1rem;
        color: #88889b;
        font-weight: 400;
        font-size: 16px;
        text-align: justify;
      }

      h3 {
        font-weight: 500;
        font-size: 24px;
      }

      .title {
        h1 {
          font-size: 55%;
        }

        span {
          font-size: 80%;
        }
      }
    }

    .automation {
      .container {
        img {
          width: 269px;
          height: 288px;
          margin-left: -7px;
        }
      }
    }
  }

  .webreathe-container {

    // .slick-list {
    //   padding: 0px 100px !important;
    // }
    .client-content {
      .client-img {
        width: 170px !important;
        height: 130px !important;
        transition: transform 0.5s ease;
        object-fit: contain;
      }

      .partners-img {
        width: 90px !important;
        height: 90px !important;
        transition: transform 0.5s ease;
        object-fit: contain;
      }
    }
  }

  .partial-container {
    height: 550px;

    // .slick-list {
    //   padding: 0px 100px !important;
    // }
    .client-content {
      .client-img {
        width: 100px !important;
        height: 100px !important;
        transition: transform 0.5s ease;
        object-fit: contain;
      }

      .partners-img {
        width: 90px !important;
        height: 90px !important;
        transition: transform 0.5s ease;
        object-fit: contain;
      }
    }
  }

  .solution-readmore {
    padding: 2rem;

    .solution-readcontent {
      margin-top: 4rem;
      padding: 0.5rem;
      display: flex;
      flex-direction: column;

      p {
        font-size: 14px;
      }

      h3 {
        font-weight: 400;
        font-size: 24px;
      }

      h2 {
        span {
          color: #ff3d00;
        }
      }

      div {
        ul {
          font-weight: 400;
          font-size: 12px !important;
          color: #818181;
          padding: 0;
          padding-right: calc(var(--bs-gutter-x) * 0.5);
          padding-left: calc(var(--bs-gutter-x) * 0.5);

          li {
            padding: 0;
            color: #818181;
            font-size: 14px;
          }
        }
      }
    }
  }
}

@media (min-width: 450px) and (max-width: 768px) {
  .partial-container {
    height: 275px;
    padding: 8%;
  }

  .readmore-img {
    img {
      width: auto;
      height: 400px !important;
    }
  }
}

@media (max-width: 425px) {
  .spring-img img {
    margin-top: -15%;
    margin-left: -24px;
    transform: rotate(-27deg);
  }

  .atrips-home {
    .header-section {
      display: flex;
      flex-direction: column;
      position: absolute;
      background-color: #fff;
      // left: 60%;
      // top: 100%;
    }

    // .contact-container {
    //   margin-top: 120% !important;
    //   .contact-form {
    //     left: 0px;
    //     .card {
    //       width: 300px !important;
    //       height: 500px !important;
    //       bottom: 160% !important;
    //       right: 0%;

    //       // width: 300px !important ;
    //       // height: 500px !important ;
    //       // bottom: 165% !important;
    //       // right: 12% !important;

    //       .form-control {
    //         height: 2rem;
    //         width: 100%;
    //         padding-top: 1rem;
    //       }
    //       .card Button {
    //         width: 100px;
    //       }
    //     }
    //   }
    // }
    .contact-container {

      // margin-top: 145% !important;
      .contact-form {
        .card {

          // width: 300px !important;
          // height: 500px;
          // bottom: 170% !important;
          // right: 12%;
          .form-control {
            height: 2rem;
            width: 100%;
            padding-top: 1rem;
          }

          .Button {
            width: 135px;
          }
        }
      }
    }

    .Career-container {
      padding: 2rem !important;

      .careerLogo {
        img {
          height: 260px !important;
        }
      }
    }

    .solution-container {
      padding: 16px !important;

      h3 {
        font-size: 24px !important;
      }

      // .slick-slide {
      //   div {
      //     div {
      //       width: 100% !important;
      //       .card {
      //         // height: auto !important;
      //         width: 299px !important;
      //         .card-body {
      //           gap: 10px !important;
      //         }
      //       }
      //     }
      //   }
      // }
    }

    .career-container {
      .careerLogo {
        padding: 0;

        img {
          height: 200px;
        }
      }
    }

    .partial-container {
      padding: 9%;

      // h3 {
      //   font-size: 32px;
      // }

      // .slick-list {
      //   padding: 0px 20px !important;
      // }
      // .slick-slide {
      //   div {
      //     width: 300px;
      //     div {
      //       width: 410px !important;
      //       .card {
      //         width: auto !important;
      //         // width: 80% !important;
      //       }
      //     }
      //   }
      // }
    }

    .Footer-container {
      padding: 2rem !important;
    }
  }

  .solution-readmore {
    .solution-readcontent {
      margin-top: 4rem;

      .readmore-img {
        height: auto;
        width: auto;
        display: flex;
        justify-content: center;

        img {
          margin-bottom: 12%;
        }
      }
    }
  }

  .webreathe-container {
    height: 500px !important;

    h3 {
      font-size: 32px;
    }

    // .slick-list {
    //   padding: 0px 20px !important;
    // }
    // .slick-slide {
    //   div {
    //     width: 300px;
    //     div {
    //       width: 410px !important;
    //       .card {
    //         width: auto !important;
    //       }
    //     }
    //   }
    // }
  }
}

@media (max-width: 449px) {
  .readmore-img {
    img {
      height: 352px !important;
    }
  }
}

@media (max-width: 375px) {
  .atrips-home {
    .Career-container {
      padding: 1rem !important;

      .careerLogo {
        img {
          height: 230px !important;
        }
      }
    }

    .contact-container {

      // margin-top: 130%;
      .contact-form {
        left: 0px;

        .card {

          // width: 300px !important;
          // height: 495px !important;
          // bottom: 162% !important;
          // right: 19% !important;
          .form-control {
            height: 2rem;
            width: 100%;
            padding-top: 1rem;
          }

          .card Button {
            width: 100px;
          }
        }
      }
    }

    // .partial-container {
    // .slick-list {
    //   padding: 0px 20px !important;
    // }
    // .slick-slide {
    //   div {
    //     width: 270px !important;
    //     div {
    //       width: 358px !important;
    //       .card {
    //         width: auto !important;
    //       }
    //     }
    //   }
    // }
    // }

    .Footer-container {
      padding: 2rem !important;
    }
  }

  // .webreathe-container {
  // .slick-list {
  //   padding: 0px 20px !important;
  // }
  // .slick-slide {
  //   div {
  //     width: 270px !important;
  //     div {
  //       width: 358px !important;
  //       .card {
  //         width: auto !important;
  //       }
  //     }
  //   }
  // }
  // }
}

@media (max-width: 320px) {
  .atrips-home {
    .contact-container {

      // margin-top: 170% !important;
      .contact-form {
        .card {

          // width: 264px !important;
          // height: 500px;
          // bottom: 170% !important;
          // right: 25%;
          .form-control {
            height: 2rem;
            width: 100%;
            padding-top: 1rem;
          }

          .Button {
            width: 135px;
          }
        }
      }
    }

    .solution-container {
      padding: 16px !important;

      h3 {
        font-size: 24px !important;
      }

      // .slick-slide {
      //   div {
      //     width: 270px !important;
      //     div {
      //       width: 100% !important;
      //       .card {
      //         // height: auto !important;
      //         .card-body {
      //           gap: 10px !important;
      //         }
      //       }
      //     }
      //   }
      // }
    }

    .Career-container {
      padding: 1rem !important;

      .careerLogo {
        img {
          height: 200px !important;
        }
      }
    }

    .atribs-container {
      .card {
        .o-title {
          font-size: 30px;
        }
      }
    }

    .Client-container {
      .client-title {
        h2 {
          font-size: 36px;
        }
      }

      div {
        .client-intelligent {
          p {
            font-size: 16px;
          }
        }
      }

      .Button {
        width: 200px;
      }
    }

    .Footer-container {
      padding: 2rem !important;
    }
  }

  .solution-readmore {
    .solution-readcontent {
      margin-top: 2rem;

      .readmore-img {
        height: auto;

        img {
          height: 260px !important;
          margin-bottom: 12%;
        }
      }
    }

    .readmore-img {
      display: flex !important;
      justify-content: center !important;

      img {
        width: 510px;
        height: 528.6px;
      }
    }
  }

  .partial-container {
    .partners-img {
      margin-right: 61px;
      object-fit: contain;
    }

    // .slick-list {
    //   padding: 0px 20px !important;
    // }
    // .slick-slide {
    //   div {
    //     width: 260px !important;
    //     div {
    //       width: 260px !important;
    //       .card {
    //         width: 260px !important;
    //       }
    //     }
    //   }
    // }
  }
}

// .slick-prev {
//   left: 3% !important;
//   z-index: 1;
// }
// .slick-next {
//   right: 3% !important;
//   z-index: 1;
//   background: none;
// }
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 1;
}

.slick-prev:before,
.slick-next:before {
  color: #000000;
}

.contactus-container {
  margin-top: 5rem;
  display: flex;
  justify-content: center;
  width: 100%;
}

.text-container {
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

// .contact-container .row {
// }
.contact-text-line1 {
  color: #ff3d00;
  font-size: 20px;
  font-weight: 500;
}

.contact-text-line2 {
  color: #313131;
  font-size: 48px;
  font-weight: 600;
}

.contact-text-line3 {
  color: #313131;
  font-size: 20px;
  font-weight: 500;
}

.contact-text-line4 {
  color: #ff3d00;
  font-size: 20px;
  font-weight: 500;
}

.flex-container-text {
  display: flex;
  flex-direction: row;
}

.flex-container-design {
  display: flex;
  flex-direction: row;
  margin-top: 2%;
  margin-right: -29%;
  width: 100%;
}

@media (min-width: 426px) and (max-width: 2560px) {
  .spring-img img {
    margin-top: -34%;
    margin-left: -24px;
    transform: rotate(-27deg);
  }
}

@media (max-width: 425px) {
  .spring-img img {
    margin-top: -15%;
    margin-left: -24px;
    transform: rotate(-27deg);
  }
}

.Address-data-container {
  display: flex;
  justify-content: center;
  margin-left: 5%;
  margin-top: 5%;
  // align-items: center;
  width: auto;

  .row .contactus-border {
    display: flex;
    // justify-content: center;
  }
}

.flex-details-container {
  display: flex;
  flex-direction: row;
}

@import url("https://fonts.googleapis.com/css?family=Poppins");

/* Popup appearance */
.tooltip {
  background-color: white;
  /* Customize the popup background color */
  border: 1px solid gray;
  /* Customize the popup border color */
  padding: 10px;
  /* Adjust the padding around the popup content */
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.popup {
  position: absolute;
  background-color: #fff;
  padding: 10px;
  border: 1px solid #ccc;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.custom-tooltip {
  background-color: white;
  color: #313131;
}

.tooltip::after {
  position: absolute;
  bottom: -2%;
  content: "";
  width: 8px;
  height: 8px;
  background: white;
  transform: rotate(45deg);
}

@media (min-width: 768px) and (max-width: 2560px) {
  .contact-container {
    .Contact-address .row {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(70px, 2fr));
      grid-gap: 1rem;
    }
  }

  .image-conctact-icon-size {
    height: 68px;
    width: 68px;
  }

  .contactus-details-content {
    padding: 2px;
    margin-left: 10px;

    .text-address {
      font-size: 22px;
      font-weight: 700;
      color: #313131;
      margin-bottom: 0;
    }

    .text-address-details {
      font-size: 18px;
      font-weight: 400;
      color: #88889b;
      margin-bottom: 0;
    }
  }
}

@media (max-width: 768px) {
  .image-conctact-icon-size {
    height: 40px;
    width: 40px;
  }

  .contactus-details-content {
    padding: 2px;
    margin-left: 8px;

    .text-address {
      font-size: 16px;
      font-weight: 700;
      color: #313131;
      margin-bottom: 0;
    }

    .text-address-details {
      font-size: 14px;
      font-weight: 400;
      color: #88889b;
      margin-bottom: 0;
    }
  }
}

.card-img-top {
  transition: transform 0.5s;
  // width: 100px;
  // height: 100px;
  margin: 0 auto;
}

.card-img-top:hover {
  -ms-transform: scale(1);
  /* IE 9 */
  -webkit-transform: scale(1);
  /* Safari 3-8 */
  transform: scale(1.1);
}

.Text-content-line {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.client-title {
  h2::first-letter {
    // font-size: 150%;
    color: #dd0505;
  }

  h2 {
    color: #002d61;
  }
}

.slick-slide .card {
  position: relative;
}

.slick-slide .card img {
  display: block;
  width: 100%;
  height: auto;
}

.slick-slide .card .card-body {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: #f1f1f1;
  overflow: hidden;
  width: 100%;
  height: 20%;
  transition: 0.5s ease;
  border-radius: 0px 0px 5px 5px;
}

.slick-slide .card:hover .card-body {
  height: 85%;
}

@keyframes slideInLeft {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}

// Forte-final-container
@media (min-width: 1251px) and (max-width: 2560px) {
  .Forte-head-container-final {
    width: 100%;
    height: 100%;

    .row {
      padding: 2% 6%;
    }

    .container-forte-final {
      width: 100%;
      height: 100%;
      position: relative;
      border-radius: 12px;

      .image-forte-overlay {
        display: block;
        width: 100%;
        height: 100%;
      }

      .hoverimage-forte-overlay {
        display: none; /* Hide the image initially */
        width: 140px;
        height: 140px;
        align-items: center;
        justify-content: center;
        align-self:center;
        // margin-top: 50px;
        position: absolute;
        bottom: 15%;
        // background-color: #fff;
        // background-color: #DFF7E1;
        background-color: #fff;


        border-radius: 10px;
        // box-shadow: 0 4px 15px rgba(1, 116, 24, 0.5);

      }
  
      &:hover .hoverimage-forte-overlay {
        display: block; /* Show the image on hover */
      }

      .overlay-effect {
        border-radius: 10px;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background-image: linear-gradient(to bottom,
            rgba(255, 0, 0, 0),
            rgba(255, 0, 0, 1));
        overflow: hidden;
        width: 100%;
        height: 10%;
        transition: 0.5s ease;

        .text-content {
          display: none;
        }

        .learn-more-text {
          display: none;
        }

        .text-forte-overlay {
          color: white;
          font-size: 18px;
          font-weight: 600;
          position: absolute;
          top: 50%;
          left: 50%;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          -webkit-transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          text-align: center;
        }
      }
    }
  }

  .container-forte-final:hover .overlay-effect {
    height: 100%;
    overflow-y: auto;
    scroll-behavior: smooth;
    background-color: #dd0505;
    opacity: 1;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .text-forte-overlay {
      display: none;
    }

    .text-content {
      display: block;
      padding: 7%;
      color: #ffffff;
      font-family: Poppins;
      font-size: 16px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 12;
      overflow: hidden;
    }

    .learn-more-text {
      display: flex;
      flex-direction: row;
      padding: 7%;
      color: #ffffff;
      font-family: Poppins;
      font-size: 20px;
    }
  }
}

@media (min-width: 768px) and (max-width: 1250px) {
  .Forte-head-container-final {
    width: 100%;
    height: 100%;

    .row {
      padding: 2% 6%;
    }

    .container-forte-final {
      position: relative;

      .image-forte-overlay {
        display: block;
        width: 100%;
        height: auto;
      }

      .hoverimage-forte-overlay {
        display: none; /* Hide the image initially */
        width: 200px;
        height: 200px;
        bottom: 0;
        align-items: center;
        justify-content: center;
        align-self: center;
      }
  
      &:hover .hoverimage-forte-overlay {
        display: block; /* Show the image on hover */
      }

      .overlay-effect {
        border-radius: 10px;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background-image: linear-gradient(to bottom,
            rgba(255, 0, 0, 0),
            rgba(255, 0, 0, 1));
        overflow: hidden;
        width: 100%;
        height: 20%;
        transition: 0.5s ease;

        .text-content {
          display: none;
        }

        .learn-more-text {
          display: none;
        }

        .text-forte-overlay {
          color: white;
          font-size: 12px;
          font-weight: 600;
          position: absolute;
          top: 50%;
          left: 50%;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          -webkit-transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          text-align: center;
        }
      }
    }
  }

  .container-forte-final:hover .overlay-effect {
    height: 100%;
    overflow-y: auto;
    scroll-behavior: smooth;
    background-color: #dd0505;
    opacity: 1;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .text-forte-overlay {
      display: none;
    }

    .text-content {
      display: block;
      padding: 9%;
      color: #ffffff;
      font-family: Poppins;
      font-size: 16px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 8;
      overflow: hidden;
    }

    .learn-more-text {
      display: flex;
      flex-direction: row;
      padding: 7%;
      color: #ffffff;
      font-family: Poppins;
      font-size: 16px;
    }
  }
}

//Hover Forte container

//final-worldmap
.worldmap-size {
  margin-top: 10rem;
  width: 100%;
}

.worldmap-addresses-container {
  // position: absolute;
  margin-top: 7rem;
  top: 10%;
  width: 100%;

  .Our-Global {
    margin-top: 2%;
    margin-left: 5%;
    padding: 10px;
    display: flex;
    flex-direction: row;
    align-items: baseline;

    h1 {
      font-size: 40px;
      font-weight: 700;
      color: #000000;
    }

    img {
      width: 14px;
      height: 14px;
    }
  }

  .cards {
    margin-top: 3%;
    margin-left: 5%;
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    width: 100%;
    height: 100%;
  }

  @media (max-width: 2560px) {
    .cards {
      .card {
        height: 160px;
        width: 460px;
      }

      .card-hover {
        height: 320px;
        width: 460px;
      }

      .card-parent {
        height: 320px;
      }
    }
  }

  @media (max-width: 900px) {
    .cards {
      .card {
        height: 100%;
        width: 360px;
      }

      .card-hover {
        height: 100%;
        width: 360px;
      }

      .card-parent {
        height: 100%;
      }
    }
  }

  .card {
    padding-top: 1rem;
    border: none;
    background-color: transparent;
    align-items: center;
    display: flex;
    border: 0.5px solid #e4e3e3;
    flex-direction: column;
    border-radius: 20px;
    transition: 0.9s ease;

    .text-content-country {
      color: #313131;
      font-size: 18px;
      font-weight: 700;
    }

    .text-content-address {
      color: #88889b;
      font-size: 16px;
    }

    .map-image {
      display: none;
    }
  }

  .card-hover {
    padding: 1rem;
    background-color: #dd0505;
    border-radius: 20px;
    flex-direction: row;
    transition: 0.5s ease;

    .text-content-country {
      padding: 0;
      color: #ffffff;
      font-size: 18px;
      font-weight: 600;
    }

    .text-content-address {
      color: #ffffff;
      font-size: 16px;
      padding: 0;
    }

    .map-image {
      position: relative;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      width: 100%;

      // height: 100%;
      img {
        width: 100%;
        height: 100%;
      }

      .locationIcon {
        position: absolute;
        right: 2%;
        bottom: 6%;
        width: 25px;
        height: 25px;
      }
    }
  }
}

//Background effects
@import url("https://fonts.googleapis.com/css?family=Montserrat:700");

.hero {
  background-color: #ffffff;
  position: relative;
  height: 100vh;
  overflow: hidden;
  font-family: "Montserrat", sans-serif;
}

.hero__title {
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 50px;
  z-index: 1;
}

// .cube {
//   position: absolute;
//   top: 80vh;
//   left: 45vw;
//   width: 10px;
//   height: 10px;
//   border: solid 1px #042835;
//   transform-origin: top left;
//   transform: scale(0) rotate(0deg) translate(-50%, -50%);
//   -webkit-animation: cube 60s ease-in forwards infinite;
//   animation: cube 10s ease-in forwards infinite;
// }

// .cube:nth-child(2n) {
//   border-color: #dd0505;
// }

// .cube:nth-child(2) {
//   -webkit-animation-delay: 1s;
//   animation-delay: 1s;
//   left: 5vw;
//   top: 5vh;
// }

// .cube:nth-child(3) {
//   -webkit-animation-delay: 3s;
//   animation-delay: 14s;
//   left: 35vw;
//   top: 50vh;
// }

// .cube:nth-child(4) {
//   -webkit-animation-delay: 2s;
//   animation-delay: 6s;
//   left: 90vw;
//   top: 30vh;
// }

// .cube:nth-child(5) {
//   -webkit-animation-delay: 9s;
//   animation-delay: 4s;
//   left: 10vw;
//   top: 85vh;
// }

// .cube:nth-child(6) {
//   -webkit-animation-delay: 5s;
//   animation-delay: 10s;
//   left: 50vw;
//   top: 10vh;
// }

// .cube:nth-child(7) {
//   -webkit-animation-delay: 2s;
//   animation-delay: 12s;
//   left: 70vw;
//   top: 60vh;
// }

// .cube:nth-child(8) {
//   -webkit-animation-delay: 7s;
//   animation-delay: 10s;
//   left: 50vw;
//   top: 40vh;
// }

// .cube:nth-child(9) {
//   -webkit-animation-delay: 6s;
//   animation-delay: 3s;
//   left: 30vw;
//   top: 90vh;
// }

// .cube:nth-child(9) {
//   -webkit-animation-delay: 1s;
//   animation-delay: 7s;
//   left: 20vw;
//   top: 15vh;
// }

// .cube:nth-child(10) {
//   -webkit-animation-delay: 6s;
//   animation-delay: 3s;
//   left: 6vw;
//   top: 30vh;
// }

// .cube:nth-child(11) {
//   -webkit-animation-delay: 5s;
//   animation-delay: 9s;
//   left: 10vw;
//   top: 25vh;
// }

// .cube:nth-child(12) {
//   -webkit-animation-delay: 2s;
//   animation-delay: 2s;
//   left: 20vw;
//   top: 60vh;
// }

// .cube:nth-child(13) {
//   -webkit-animation-delay: 2s;
//   animation-delay: 2s;
//   left: 90vw;
//   top: 10vh;
// }

// .cube:nth-child(14) {
//   -webkit-animation-delay: 2s;
//   animation-delay: 2s;
//   left: 90vw;
//   top: 60vh;
// }

// .cube:nth-child(15) {
//   -webkit-animation-delay: 2s;
//   animation-delay: 2s;
//   left: 80vw;
//   top: 50vh;
// }

// .cube:nth-child(16) {
//   -webkit-animation-delay: 2s;
//   animation-delay: 2s;
//   left: 80vw;
//   top: 80vh;
// }

@-webkit-keyframes cube {
  from {
    transform: scale(0) rotate(0deg) translate(-50%, -50%);
    opacity: 0.5;
  }

  to {
    transform: scale(10) rotate(960deg) translate(-50%, -50%);
    opacity: 0;
  }
}

@keyframes cube {
  from {
    transform: scale(0) rotate(0deg) translate(-50%, -50%);
    opacity: 0.5;
  }

  to {
    transform: scale(5) rotate(960deg) translate(-50%, -50%);
    opacity: 0;
  }
}

.careerLogo {
  width: 600px;
  height: 350px;
  position: relative;
}

.careerLogo img {
  position: absolute;
  transform-origin: left;
}

.image-1 {
  clip-path: polygon(0 0, 0 32%, 28% 0);
  transform: translate(-10px, -10px);
  transition: transform 300ms ease;
  transform-origin: top;

  .careerLogo:hover & {
    transform: translate(0, 0);
  }
}

.image-2 {
  clip-path: polygon(28% 0, 0 32%, 0 100%, 17% 100%, 90% 0);
  transform: translate(-30px, 5px);
  transition: transform 300ms ease;

  .careerLogo:hover & {
    transform: translate(0, 0);
  }
}

.image-3 {
  clip-path: polygon(100% 0, 100% 65%, 74% 100%, 17% 100%, 90% 0);
  transform: translate(-15px, -10px);
  transition: transform 300ms ease;

  .careerLogo:hover & {
    transform: translate(0, 0);
  }
}

.image-4 {
  clip-path: polygon(100% 65%, 74% 100%, 100% 100%);
  transform: translate(-32px, 5px);
  transition: transform 300ms ease;
  transform-origin: top;

  .careerLogo:hover & {
    transform: translate(0, 0);
  }
}

.learnMoreBtn {
  border: none;
  background-color: transparent;
  color: white;
  font-size: 18px;
}

.learnMoreBtn:hover {
  font-size: 20px;
  transition: 0.5s;
}

//box-shadow-flags

.flags-head {
  padding: 18% 1% 2%;

  .col {
    padding: 8px;
    width: 100%;
  }
}

.box-shadow-flags {
  transition: transform 0.5s ease-in-out;
  height: 70px;
  width: 70px;
  border-radius: 50%;
  border: 2px solid #042835;
}

.box-shadow-flags:hover {
  transform: scale(1.9);
  box-shadow: 0 0 15px #042835;
  transform: rotate(360deg);
}

.our-forte {
  font-size: 14px;
  color: #06442f;
  transition: 2s;
}


// Our Team page --------------------

.ourTeam_container {
  margin-top: 120px;

  .left-arrow {
    margin-left: 5%;
    cursor: pointer;
    background-color: #114957;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    transition: transform 0.2s;
    height: 40px;
    box-shadow: 0 0 50px #f9bebe;

    &:hover {
      background-color: #e2262f;
      -ms-transform: scale(1);
      -webkit-transform: scale(1);
      transform: scale(1.1);
    }

    i {
      font-size: 1.1rem;
      color: #ffffff;
      width: 11px;
      height: 11px;
    }
  }

  .heading_grp {
    display: flex;
    justify-content: center;

    div {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;

      h6 {
        color: rgba(255, 61, 0, 0.52);
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 2.56px;
        text-align: center;
      }

      h1 {
        text-align: center;
        margin-top: 22px;
        color: #313131;
        text-align: center;
        font-size: 40px;
        font-weight: 700;
        letter-spacing: 6.4px;
        font-family: Poppins;

        span {
          color: #FF3D00;
        }
      }

      h3 {
        margin-top: 30px;
        margin-bottom: 5px;
        text-align: center;
      }

      .line {
        height: 3px;
        width: 120px;
        background-color: #000;
        border-radius: 20px;
      }
    }

  }

  .card-area {
    margin: 20px 0;
    // display: grid;
    // grid-template-columns: repeat(auto-fit, minmax(376px, 1fr));
    // grid-gap: 1rem;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;

    .card_content {
      flex-direction: column;
      align-items: center;

      .card_img {
        width: 300px;
        height: auto;
        object-fit: contain;
        aspect-ratio: 3/3;
        filter: grayscale(100%);
        transition: transform 0.3s ease-in-out;

        &:hover {
          filter: none;
          transform: scale(1.1);
        }
      }

      h6 {
        text-align: center;
        color: #000;
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 2.56px;
        text-transform: uppercase;
      }

      p {
        text-align: center;
        color: rgba(255, 61, 0, 0.54);
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0.96px;
        margin: 0;
      }

      .card_text_area {
        width: 200px;
        background: #FFFFFF;
        position: relative;
        bottom: 30px;
        padding: 10px 20px;
      }
    }
  }

  .card-area-1>div {
    flex-basis: 25%;
  }

  .card-area-2>div {
    flex-basis: 30%;
  }
}

// Responsive--------

@media (min-width: 320px) and (max-width: 767px) {
  .ourTeam_container {
    margin-top: 100px;

    .heading_grp {
      div {
        width: 100%;
      }
    }

    .card-area {
      display: block;
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .ourTeam_container {
    margin-top: 100px;

    .heading_grp {
      div {
        width: 70%;
      }
    }
  }
}